import theme from "@nutrafol/nutrafol-ui-kit/styles/theme"

import Carrot from "../../assets/icons/master/carrot.svg"
import Wave from "../../assets/icons/master/wave.svg"
import Gift from "../../assets/icons/master/gift.svg"
import Headspace from "../../assets/icons/headspace.svg"
import Money from "../../assets/icons/master/money.svg"
import Pill from "../../assets/icons/master/pill.svg"
import Chat from "../../assets/icons/master/chat.svg"
import Doctor from "../../assets/icons/master/doctor.svg"
import NumberOne from "../../assets/icons/men/men-gp-number-one.svg"
import Beaker from "../../assets/icons/men/men-gp-beaker.svg"
import Leaf from "../../assets/icons/men/men-gp-leaf.svg"
import Heart from "../../assets/icons/men/men-gp-heart.svg"

export const data = {
  seo: {
    title: "GrowthPlan+",
    description:
      "The Nutrafol GrowthPlan+ builds a customized plan to support your hair health.",
  },
  touchPoints: {
    head: `GROWTHPLAN+`,
    title: `Get the GrowthPlan+ Guarantee.`,
    subtitle: `The premium plan you can count on, or your money back.`,
    link: `/men/growthplanplus/`,
    label: `Learn More`,
  },
  banner: {
    image:
      "https://images.ctfassets.net/0rbfqd9c4jdo/hPeBzkZ6htPIriqcJb2ll/b35c69d1440e7d58fbe235478f24bdb9/concierge-men-desktop.jpg?fl=progressive",
    imageMobile:
      "https://images.ctfassets.net/0rbfqd9c4jdo/67J9Df1lrUOxYsS3Cv3rrG/ad0f6fa35d84a229e5e7091613e96b71/gp-banner-mobile.jpg?fl=progressive",
    title: "Growth without<br/> limits—GrowthPlan+",
    copy: [
      "Unlimited access to naturopathic doctors to guide your hair health journey.",
      "Every product you need for success, delivered to your door for $299/month.",
      "Results in 6 months or your money back.*",
    ],
    button: {
      label: "Get Started",
      url: "/men/growthplanplus/scheduling/",
    },
  },
  scrollbanner: {
    copy1: "#1 dermatologist-recommended hair growth supplement brand†",
    copy2: "100% drug-free ingredients",
    copy3: "Physician formulated",
  },
  steps: [
    {
      name: "Consultation",
      title: "Connect with a  Naturopathic <br/>Doctor.",
      body: "Discuss your hair, health, and your daily life, then review photos and get your personalized GrowthPlan+ from your ND. You’ll stay connected with them throughout your journey.",
      bgColor: "#07253A",
      image:
        "https://images.ctfassets.net/0rbfqd9c4jdo/314jgloCdZYufJYS04n5cp/c1b14a92d4008329cbc4899ac427d855/gp-step1-desktop.png?fl=progressive",
      imageMobile:
        "https://images.ctfassets.net/0rbfqd9c4jdo/kIQkrXRBBCn53rTF3ojN0/36886c9bd81f6d892bb0e8cdb17b3057/gp-step1-mobile.png?fl=progressive",
    },
    {
      name: "Products",
      title: "Every product you need to get the hair you want.",
      body: "Your recommended plan includes the right Hair Growth Nutraceutical for you and any hair wellness boosters and scalp support you might need, delivered monthly. ",
      bgColor: "#0F3956",
      image:
        "https://images.ctfassets.net/0rbfqd9c4jdo/6rFuJjlSOZeutKVjXYCunW/492dde2d43a58d4b01a5f28e3df59bb1/gp-step2-desktop.png?fl=progressive",
      imageMobile:
        "https://images.ctfassets.net/0rbfqd9c4jdo/6POB9YEDytdzEJm7LRRyoQ/a654d441188144d15afed33cc8499ffa/gp-step2-mobile.png?fl=progressive",
    },
    {
      name: "Progress",
      title: "Expert guidance rooted in data.",
      body: "Send us a hair sample and we’ll run a Hair Mineral Analysis to help your ND determine potential causes of hair thinning. Regular progress photos and check-ins will guide any plan adjustments.",
      bgColor: "#07253A",
      bgColor2: "#f7f7f5",
      image:
        "https://images.ctfassets.net/0rbfqd9c4jdo/5aa3oaWuCU3hhecW8MUzLy/41e1854479bf6f5bae79f9b0a670addb/gp-step3-desktop.png?fl=progressive",
      imageMobile:
        "https://images.ctfassets.net/0rbfqd9c4jdo/6bAZKHJtwTVsgksumEnDqk/ef5de6824989847dedb733a65c564962/gp-step3-mobile.png?fl=progressive",
    },
  ],
  features: {
    imageArea: {
      image:
        "https://images.ctfassets.net/0rbfqd9c4jdo/pWKKLsrMBuyRm78L2xEwC/37035ca23691ae01596d2aeb0e71be15/gp-features-desktop.jpg?fl=progressive",
      imageMobile:
        "https://images.ctfassets.net/0rbfqd9c4jdo/AFzAIp3883I5AWirVniNr/a563debad6f0dde2bdb53747195584a7/gp-features-mobile.jpg?fl=progressive",
      title: "An all-inclusive hair <br class='hide-md-up'/>health plan",
      subtitle: "Only $299/month",
    },
    includes: {
      tag: "Your Plan Includes:",
      bgColor: "#0F3956",
      list: [
        {
          icon: Pill,
          feature: "Unlimited monthly products",
        },
        {
          icon: Doctor,
          feature: "Unlimited access to naturopathic doctors",
        },
        {
          icon: Carrot,
          feature: "Nutrition support",
        },
        {
          icon: Chat,
          feature: "Priority customer support",
        },
        {
          icon: Wave,
          feature: "Hair mineral analaysis",
        },
        {
          icon: Headspace,
          feature: "Headspace subscription",
        },
        {
          icon: Gift,
          feature: "Exclusive gifts",
        },
        {
          icon: Money,
          feature: "6-month results money back guarantee*",
        },
      ],
      button: {
        url: "/men/growthplanplus/scheduling/",
        label: "Learn more about what's included",
      },
    },
  },
  experts: {
    stats: [
      {
        icon: NumberOne,
        stat: "The <b>#1 dermatologist-recommended</b> hair growth supplement brand.†",
      },
      {
        icon: Beaker,
        stat: "Leading the industry in hair research with <b>26 publications</b> on hair wellness research including our clinical studies.",
      },
      {
        icon: Leaf,
        stat: "Our supplements use <b>100% drug-free ingredients</b>.",
      },
      {
        icon: Heart,
        stat: "A team of <b>licensed naturopathic doctors</b> who offer personalized hair wellness coaching.",
      },
    ],
    imageArea: {
      image:
        "https://images.ctfassets.net/0rbfqd9c4jdo/3jMd7peezi2HObnYPfokDY/7749125879ef8cb12e544cf873920963/gp-experts-desktop.jpg?fl=progressive",
      imageMobile:
        "https://images.ctfassets.net/0rbfqd9c4jdo/72CMpVMHoBEVA4u18FnqDj/a9e54dc7fd0cd34d966ada6764c81e08/gp-experts-mobile.jpg?fl=progressive",
      title: `Join the<br class='hide-sm-up'/> ${process.env.GATSBY_NUTRAFOL_GROWING_NUMBER} million+ growing with Nutrafol.`,
      subtitle:
        "As experts in hair health, you can trust us to build the GrowthPlan+ that’s right for you—guaranteed.*",
    },
    expertsSlider: {
      pt: [80, 80, 104],
      pb: [40, 40, 40, 80],
      tag: "Meet the Experts",
      bgColor: theme.wild.color.men.secondary.secondary,
      bgColor2: theme.wild.color.men.secondary.default,
      slides: [
        {
          image:
            "https://images.ctfassets.net/0rbfqd9c4jdo/5HwT6UAm6WGCH6qRDGBeeX/a87f118a64ed4d1bdfd410836ef7c189/expert-kasunich.png?fl=progressive",
          name: "Dr. Anjali Kasunich, ND",
          about:
            "Uses a compassionate approach to help gently guide clients and help them understand possible root causes of their hair thinning.",
        },
        {
          image:
            "https://images.ctfassets.net/0rbfqd9c4jdo/3gGd4edOHKzhgJi2G1vS4w/f3462873a86648c8fe9662cf247a73c8/expert-zastrow.png?fl=progressive",
          name: "Dr. Ariana Zastrow, ND",
          about:
            "Specializes in hormone health and utilizes a holistic approach with a wide range of tactics to help her clients achieve health goals.",
        },
        {
          image:
            "https://images.ctfassets.net/0rbfqd9c4jdo/4ueVJXqndlPMhiJgqF6Lxo/fe8dbbb591cbd2f3a864ed3f98d64955/expert-noe.png?fl=progressive",
          name: "Dr. Kara Noe, ND",
          about:
            "Focuses on thyroid health and immune system support, and has developed her own method for addressing these via digestive, stress, hormone, and other lifestyle support.",
        },
        {
          image:
            "https://images.ctfassets.net/0rbfqd9c4jdo/1E85lW5HhKuNOzsUUEgY6I/8ca7ee36744c230b82d4bf3f3adba29a/expert-olsen.png?fl=progressive",
          name: "Dr. Kali Olsen, ND, L.Ac, MS",
          about:
            "Uses her varied educational background to support whole-body wellness including healthy hair growth and practices with a focus in holistic skin support and graceful aging.",
        },
        {
          image:
            "https://images.ctfassets.net/0rbfqd9c4jdo/5J5JViBXAOrmeJHD5kQatb/fce9242096c5c77ceb24577e691ea82b/expert-houser.png?fl=progressive",
          name: "Dr. Marisa Houser, ND, L.Ac",
          about:
            "Utilizes a holistic approach and a wide range of tactics to help her clients achieve their health goals, including hair health.",
        },
      ],
    },
    customersSlider: {
      pt: [32, 32, 40, 80],
      pb: [32, 32, 40, 80],
      bgColor: theme.wild.color.men.secondary.default,
      bgColor2: theme.wild.color.men.secondary.secondary,
      slides: [
        {
          quote:
            "I really appreciated the thorough analysis and recommendations the naturopathic doctor made! I feel well educated now and equipped to make progress on my hair health journey.",
          quoteClass: "title--medium",
          doctor: "Dr. Anjali Kasunich, ND",
        },
        {
          quote:
            "Dr. Kali was great, she asked me all the necessary questions to better understand my lifestyle, routine, and health history to ensure she was making the best possible recommendations.",
          quoteClass: "title--medium",
          doctor: "Dr. Kali Olsen, ND",
        },
        {
          quote: `I learned so much from her during our 30-minute conversation. She sent some super helpful information on factors that I didn't even realize could influence my hair growth.`,
          quoteClass: "title--medium",
          doctor: "Dr. Kara Noe, ND",
        },
      ],
    },
  },
  quoteBanner: {
    image:
      "https://images.ctfassets.net/0rbfqd9c4jdo/4SoxvSqmuErPhLq0JgVudO/80900f1882a4c923ffecff22bfb3556c/giorgos-testimonial-desktop.jpg?fl=progressive",
    imageMobile:
      "https://images.ctfassets.net/0rbfqd9c4jdo/ZnkrnrCsuWjlGRmLmPYeO/7c9dbb65308156e8f592b5079a600af9/giorgos-testimonial-mobile.jpg?fl=progressive",
    quote:
      "“I know firsthand how <br class='show-md-up'/>overwhelming hair thinning can be. It’s why I started Nutrafol, and why my team and I are committed to providing the resources and support you need to see change in your hair.”",
    name: "-Giorgos Tsetis",
    title: "Nutrafol Co-founder and Chairman",
  },
  questions: [
    {
      title: "How is Nutrafol different from other hair growth products?",
      answer:
        "We’re a company that recognizes the importance of meeting you where you are in life. Our drug-free nutraceutical formulas are different from other hair growth products because they’re formulated for your bio-specific needs. Through our nutraceuticals, we take a multi-targeted approach that successfully addresses root causes of thinning hair to support visibly longer, stronger hair growth with vitamins, minerals, and natural ingredients. ",
    },
    {
      title: "How will my progress be measured?",
      answer:
        "You will work closely with a naturopathic doctor who, along with recommending a customized product plan to you, will also check in at 3 and 6 months touch points with photo tracking and in-depth evaluations of your progress. ",
    },
    {
      title:
        "What does a GrowthPlan+ Initial Consultation with a naturopathic doctor entail?",
      answer:
        "Your consultation with our naturopathic doctor will include a detailed discussion regarding your hair concerns—what you’ve been experiencing, and for how long; how you’ve addressed your hair concerns in the past; and how your hair struggles have impacted your life. Next, you’ll explore lifestyle factors—such as diet, daily stressors, quality of sleep, digestive function, and overall health. With this information, your ND will be able to recommend the Nutrafol products that are the best fit for you, help you to understand how ingredients are supporting your hair health, offer you lifestyle recommendations and important tools to incorporate into your daily routine, and answer any questions about Nutrafol, your plan, or your hair. ",
    },
    {
      title: "What products are included?",
      answer:
        "Product selection is dependent on your specific needs and will be determined by the naturopathic doctor post-consultation. This will include the Hair Growth Nutraceutical that is right for you, as well as Hair Wellness Boosters or scalp products that your ND recommends for you.",
    },
    {
      title: "What is Hair Mineral Analysis?",
      answer:
        "A Hair Mineral Analysis (HMA) is a kit that allows you to send a hair sample to be analyzed by our lab partner. The results help provide your ND with information and data about your hair health. It is used to help determine the root causes of hair thinning that are specific to you so that we can build out and refine a personalized plan that best supports your hair health.",
    },
    {
      title: "What does GrowthPlan+ “Unlimited Access to Experts” entail?",
      answer:
        "At any point throughout your journey you can schedule a GrowthPlan+ Follow-up Consultation with your Nutrafol naturopathic doctor. We also provide you a phone number where you can text brief questions and receive a response from the Nutrafol naturopathic doctor team within 1-2 business days.",
    },
  ],
  ctaBanner: {
    image:
      "https://images.ctfassets.net/0rbfqd9c4jdo/7KbRDAttO9jnK7XgysOa5y/bcc792f5563954a7af254e5b1344ec4f/gp-cta-desktop.jpg?fl=progressive",
    imageMobile:
      "https://images.ctfassets.net/0rbfqd9c4jdo/7yTpD78p5BrKrBWeUR9yAr/67377e4bff039d80c0e383f2f7a40f16/gp-cta-mobile.jpg?fl=progressive",
    title: "Get Your GrowthPlan+",
    ctaButton: {
      label: "Schedule Your Consultation",
      url: "/men/growthplanplus/scheduling/",
    },
    info: "A GrowthPlan+ subscription is $299/month. You will book your first consultation now and will be charged $299 + applicable taxes when you complete checkout. In your initial consultation your naturopathic doctor will help you determine if GrowthPlan+ is right for you.",
    learnMoreButton: {
      label: "*Learn more about our GrowthPlan+ Guarantee.",
      url: "/men/growthplanplus/scheduling/",
    },
  },
  ref: {
    button: {
      url: "/men/growthplanplus/scheduling/",
      label: "*Learn more about our GrowthPlan+ Guarantee",
    },
    title:
      "In your initial consultation your naturopathic doctor will help you determine if GrowthPlan+ is right for you. ",
    copy: "†According to IQVIA ProVoice survey for 12 months ending March 31, 2023.",
  },
  sixMonthGuarantee: {
    disclaimer:
      "In your initial consultation your naturopathic doctor will help you determine if GrowthPlan+ is right for you.",
    disclaimer2:
      "✝According to IQVIA ProVoice survey for 12 months ending March 31, 2023.",
    copy: "*Learn more about the GrowthPlan+ Guarantee",
    modalData: {
      title: "GrowthPlan+ Guarantee Terms & Conditions",
      items: [
        {
          title: "item-1",
          text: "If you have not experienced results, after 6 months of consistently following the plan designed for you by our naturopathic doctors, you may be eligible for a full refund. Eligibility is contingent upon your submission of photos that follow the photo guide included in the Welcome Kit and email; photos must be taken at the onset of the program, at 3-months and at 6-months. You must also have completed at least two consultations with a Nutrafol naturopathic doctor. Results are determined by an unbiased, independent qualified dermatologist, provided by Nutrafol, who will review photos for changes in hair quality including scalp coverage, density, overall volume, or texture and shine. Using the Hair Quality Global Improvement Scale, the dermatologist will assess changes to determine if you are eligible for a refund under the GrowthPlan+ Guarantee.",
        },
        {
          title: "item-2",
          text: "Contact us by emailing <a href='mailto:growthplanplus@nutrafol.com' class='font-bold text-master-primary-default'>growthplanplus@nutrafol.com</a> to confirm eligibility. Please include your full name and phone number with your request. Refunds will be made via PayPal. Please allow up to 30 days to receive your refund following validation of eligibility.",
        },
      ],
    },
    pt: [80, 80, 128, 160],
    pb: [80, 80, 128, 160],
  },
  getStartedCta: {
    pt: [40, 40, 64, 80],
    pb: [40, 40, 64, 80],
    title: "Get started with your personalized GrowthPlan+.",
    copy: "*Learn more about our GrowthPlan+ Guarantee.",
    button: {
      text: "Schedule your consultation",
      url: "/men/growthplanplus/scheduling/",
    },
    disclaimer:
      "A GrowthPlan+ subscription is $299/month. You will book your first consultation now and will be charged $299 + applicable taxes when you complete checkout. In your initial consultation your naturopathic doctor will help you determine if GrowthPlan+ is right for you.",
  },
}
